import React from "react";
import { Card, Col, Layout, Row } from "antd";
import { Header } from "../../components/Header";

const Home = () => {
  return (
    <Layout>
      <Header title="Dashboard" />

      <div className="p-5">
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card title="USERS" bordered={false}>
            Total Users
            <p className="text-4xl font-medium text-right mt-5">150</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="PHOTOGRAPHERS" bordered={false}>
            Total Photographers
            <p className="text-4xl font-medium text-right mt-5">124</p>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="BOOKINGS" bordered={false}>
            Total Booking
            <p className="text-4xl font-medium text-right mt-5">100</p>
          </Card>
        </Col>
      </Row>
      </div>
    </Layout>
  );
};

export default Home;

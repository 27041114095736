import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ErrorHandler from "../utils/ErrorHandler";

const token = Cookies.get("@token");

axios.defaults.baseURL =
  "https://ghostemployee.com/picture-prfct/public/api/admin";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api/admin";
// axios.defaults.baseURL = "http://192.168.100.11:8000/api/admin";
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axios.defaults.headers.post["Accept"] = "application/json";

// axios.interceptors.request.use(
//   (request) => {
//     console.log(request);
//     // Edit request config
//     return request;
//   },
//   (error) => {
//     console.log(error);
//     return Promise.reject(error);
//   }
// );

axios.interceptors.response.use(
  (response) => {
    // console.log(response);
    // Edit response config
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("@token");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const baseState: any = {
  token: null,
  user: null,
  setUser: null,
  logout: () => {},
};

const AuthContext = createContext(baseState);

const AuthContextProvider = ({ children }: any) => {
  const [user, setUser]: any = useState<any>();
  const [users, setUsers]: any = useState<any>([]);

  useEffect(() => {
    if (token) {
      const cancelTokenSource = axios.CancelToken.source();
      const config = {
        cancelToken: cancelTokenSource.token,
        params: {},
      };

      axios
        .get(`me`, config)
        .then((res) => {
          if (res.status === 200) {
            const { data } = res.data.response;
            setUser(data);
          }
        })
        .catch((e) => {
          ErrorHandler.showNotification(e);
        });

      return cancelTokenSource.cancel;
    } else {
      setUser(null);
      if (!["/authenticate", "/"].includes(window.location.pathname)) {
        window.location.href = "/authenticate";
      }
    }
  }, []);

  const logout = () => {
    axios
      .post(`logout`, {
        // device_token: ''
        // udid: ''
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          Cookies.remove("@token");
          setUser(null);
          window.location.href = "/";
        }
      })
      .catch((err) => {
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        logout,
        user,
        setUser,
        users,
        setUsers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };
export default AuthContext;

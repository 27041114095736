import React, { useContext, useEffect } from "react";
import { SideDrawer } from "../SideDrawer";
import { Navigate, useLocation } from "react-router-dom";
import { Layout } from "antd";
import AuthContext from "../../context/AuthContext";

const AppContainer = ({ children }) => {
  const {token} = useContext(AuthContext);
  const location = useLocation();
  const isSplash = ["/", "/authenticate", "/forgot"].includes( location.pathname );

  return (
    <div className="App">
      <Layout style={{ height: "calc(100vh)" }}>
        {!isSplash && <SideDrawer />}
        <Layout className="flex-col">{children}</Layout>
      </Layout>
    </div>
  );
};

export default AppContainer;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { RouterProvider, createBrowserRouter, createHashRouter } from "react-router-dom";

import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import AppContainer from "./components/layouts/AppContainer";
import Home from "./views/home";
import Login from "./views/Login";
import Users from "./views/users";
import { AuthContextProvider } from "./context/AuthContext";

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import UserView from "./views/users/UserView";
import Forgot from "./views/Forgot";
import Banners from "./views/banners";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ar: {
        translation: require("./localization/ar.json"),
      },
    },
    lng: "ar", // if you're using a language detector, do not define the lng option
    fallbackLng: "ar",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));

const renderRoute = (Component) => {
  return <AppContainer>{Component}</AppContainer>;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: renderRoute(<Login />),
  },
  {
    path: "/forgot",
    element: renderRoute(<Forgot />),
  },
  {
    path: "/dashboard",
    element: renderRoute(<Home />),
  },
  {
    path: "/users",
    element: renderRoute(<Users />),
  },
  {
    path: "/users/:userId",
    element: renderRoute(<UserView />),
  },
  {
    path: "/banners/:bannerId?",
    element: renderRoute(<Banners />),
  },
  // {
  //   path: "/category/:categoryId?",
  //   element: renderRoute(<Categories />),
  // },
  // {
  //   path: "/service/:serviceId?",
  //   element: renderRoute(<Service />),
  // },
  // {
  //   path: "/outlets/:outletId?",
  //   element: renderRoute(<Outlets />),
  // },
  // {
  //   path: "/inventory/:inventoryId?",
  //   element: renderRoute(<Inventory />),
  // },
  // {
  //   path: "/therapists/:therapistId?",
  //   element: renderRoute(<Therapists />),
  // },
  // {
  //   path: "/requests/:requestId?",
  //   element: renderRoute(<Requests />),
  // },
  // {
  //   path: "/orders/:orderId?",
  //   element: renderRoute(<Orders />),
  // },
  // {
  //   path: "/reservations/:reservationId?",
  //   element: renderRoute(<Reservations />),
  // },
  // {
  //   path: "/invoices/:invoiceId?",
  //   element: renderRoute(<Invoices />),
  // },
  // {
  //   path: "/settings/:slug?",
  //   element: renderRoute(<Settings />),
  //   children: [
  //     {
  //       path: "services",
  //       element: <Services />,
  //     },
  //     {
  //       path: "general",
  //       element: <Services />,
  //     },
  //     {
  //       path: "change-password",
  //       element: <ChangePassword />,
  //     },
  //   ],
  // },
]);

root.render(
  <AuthContextProvider>
    <RouterProvider router={router} />
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React, { useContext, useState } from "react";
import "../index.css";
import logoImg from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import { Button } from "../components/Button";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import ErrorHandler from "../utils/ErrorHandler";

const Forgot = () => {
  const { token, setUser } = useContext(AuthContext);
  const [form] = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    axios
      .post("/forgot-password", values)
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status === 200) {
          // const userData = res.data.response.data;
          // setUser(userData);
          // Cookies.set("@token", userData.token);
          // axios.defaults.headers.common[
          //   "Authorization"
          // ] = `Bearer ${userData.token}`;
          // navigate("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <div className="splash-container w-full">
      <div className="overlay flex-col p-5">
        <img src={logoImg} alt="Logo" style={{ width: 100, height: 100, marginBottom: 20 }} />
        <div className="bg-white p-5 w-full md:w-1/3 rounded-xl">

          <h1 className="font-semibold text-lg text-center">Forgot password</h1>
          <p className="text-center mb-5 text-sm">Enter your email for the verification process, we will send password reset link to your email.</p>

          <Form
            ref={form}
            layout="vertical"
            onFinish={onSubmit}
            autoComplete={false}
          >
            <Form.Item
              className="mb-3"
              label={"Email"}
              name={"email"}
              autoComplete={false}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input placeholder="Enter your email address or username" />
            </Form.Item>
            <div className="text-center">
            <Button title="FORGET PASSWORD" loading={loading} htmlType={"submit"} />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Forgot;

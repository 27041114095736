export const LoadingIndicator = ({ visible = false }) => {
  return (
    <div
      style={{
        backgroundColor: "#00000066",
        width: "100%",
        height: "100%",
        position: "fixed",
        zIndex: 10,
        display: visible ? "block" : "none",
      }}
    ></div>
  );
};

import React, { useEffect } from "react";
import { Layout, Space, Button, Table, Tooltip, Popconfirm, Image } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { BannerForm } from "./BannerForm";
import { Header } from "../../components/Header";
import moment from "moment";

const Banners = () => {
  const navigate = useNavigate();
  const formRef = React.useRef<any>();
  const { outletId } = useParams();
  const [reload, setReload] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/banners`)
      .then((res) => {
        setLoading(false);
        if (res.status < 300) {
          setList(res.data.response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  }, [reload]);

  const onDelete = (id: number) => {
    setLoading(true);
    axios
      .delete(`/banners/${id}`)
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setReload(!reload);
          ErrorHandler.showNotification(res);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
    },
    {
      title: "Image",
      dataIndex: "image_url",
      render: (imageUrl: any) => (
        <Image src={imageUrl} width={100} height={100} />
      ),
    },
    {
      title: "Created At",
      dataIndex: "updated_at",
      render: (date: string) => moment(date).format("lll"),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_: any, row: any) => (
        <Space>
          <Tooltip title={"Edit"}>
            <EditOutlined
              className="text-green-800"
              size={18}
              onClick={() => formRef.current.open(row)}
            />
          </Tooltip>
          <Tooltip title={"Delete"} placement="bottom">
            <Popconfirm
              title="Confirmation"
              description={"Are you sure you want to delete this user?"}
              onConfirm={() => onDelete(row.id)}
            >
              <DeleteOutlined className="text-red-500" size={18} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <Header
        title="Banners"
        desc="You can manage your application banners from here"
      />
      <div className="p-5">
        <div className="float-right mb-5">
          <Button
            loading={loading}
            className="primary-btn"
            onClick={() => formRef.current.open()}
          >
            Add Banner
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={list}
          size="small"
          loading={loading}
        />
      </div>
      <BannerForm ref={formRef} onComplete={() => setReload(!reload)} />
    </Layout>
  );
};

export default Banners;

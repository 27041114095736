import React, { useEffect } from "react";
import {
  Modal,
  Form,
  InputNumber,
  Upload,
  Select,
  Button,
  Input,
  Image,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";

export const BannerForm = React.forwardRef((props: any, ref) => {
  const [form] = useForm<any>();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<any>();

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setData(undefined);
    } else {
    }
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: (banner: any = null) => {
      setVisible(true)
      if(banner) {
        setData(banner);
        form.setFieldsValue(banner)
      }
    },
    close: () => setVisible(false),
  }));

  const onSubmit = (values: any) => {
    setLoading(true);
    
    var formData = new FormData();
    formData.append("_method", values.id ? "PUT" : "POST");
    for (var key in values) {
      if (key == "image" && values[key]) {
        formData.append(key, values[key][0].originFileObj);
      } else {
        formData.append(key, values[key] || "");
      }
    }

    axios.post(values.id ? `/banners/${values.id}` : `banners`, formData)
    .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          props.onComplete();
          ErrorHandler.showNotification(res);
          setVisible(false);
        };
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal
      title={"Add Banner"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      okText={"Submit"}
      confirmLoading={loading}
      onOk={() => form.submit()}
      okButtonProps={{ className: "submit-btn" }}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        className="mt-10"
        autoComplete="off"
      >
         <Form.Item
          label=""
          style={{ display: "none" }}
          name={"id"}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Type"
          name={"type"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Select options={[{
            label: 'Banner',
            value: 'banner'
          },{
            label: 'List',
            value: 'list'
          },]} />
        </Form.Item>

        <Form.Item
          label="Sort Order"
          name={"sort_order"}
        >
          <InputNumber className="w-full" placeholder="Enter sort order" />
        </Form.Item>

        { data?.image_url ? <Image src={data?.image_url} width={150} height={150} /> : <Form.Item
          label="Banner Image"
          name={"image"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            {...props}
            beforeUpload={() => false}
            accept="image/*"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>}

      </Form>
    </Modal>
  );
});

import { Button as AntButton } from "antd";
import React from "react";
import { COLORS } from "../constant";

interface Props {
  title: string;
  className?: string;
  bgColor?: string;
  disabled?: boolean;
  onClick?: () => {} | any;
}

export const Button = (props: Props) => {
  const {
    title,
    className,
    bgColor = 'bg-gradient-to-r from-teal-600 to-green-600',
    disabled = false,
    onClick = () => {},
  } = props;
  return (
    <AntButton
      {...props}
      className={`${className} 
      ${
        disabled ? "bg-gray-500" : bgColor
      } rounded-md mt-2 text-white`}
      onClick={onClick}
    >
      {title}
    </AntButton>
  );
};

import React from 'react';
import {notification} from 'antd';

class ErrorHandler {
  constructor(error) {
    let message;
    let errors = null;
    let type = 'info';
    const response = error?.response;

    if (response) {
      switch (response.status) {
        case 400:
          type = 'warning';
          break;
        case 500:
          type = 'error';
          break;
        default:
          //do nothing;
          break;
      }

      if ('error' in response.data) {
        message = response.data.error.messages[0];
      } else if ('response' in response.data) {
        message = response.data.response.messages[0];
      } else {
        message = response.data.message;
        errors = response.data.errors;
      }
    } else {
      if (typeof error === 'string') {
        message = error;
      } else if (error.data && 'response' in error?.data) {
        message = error.data?.response?.messages[0];
      } else {
        message = 'Something wents wrong';
      }
    }

    this.descrption = message;
    this.type = type;
    this.errors = errors;
  }

  async getBlobError(error) {
    const status = await error.response.data.text();
    const st = JSON.parse(status);
    this.descrption = st.status?.msg;
  }

  static showNotification(error, time = 3) {
    const handler = new ErrorHandler(error);

    if (error.response) {
      if (error.response.config.responseType === 'blob') {
        handler.getBlobError(error).then(() => {
          handler.showNotification(time);
        });
      } else {
        handler.showNotification(time);
      }
    } else {
      handler.showNotification(time);
    }
  }

  showNotification(time) {
    notification.open({
      type: this.type,
      duration: this.errors ? 15 : time,
      message: this.descrption,
      description: this.errors ? (
        <>
          {Object.keys(this.errors).map((key, indexA) => {
            return (
              <div key={'p_' + indexA}>
                <strong>{key}</strong>
                <ul style={{paddingLeft: 16}}>
                  {this.errors[key].map((msg, indexB) => (
                    <li key={`${indexA}_${indexB}`}>{msg}</li>
                  ))}
                </ul>
              </div>
            );
          })}
        </>
      ) : null,
    });
  }

  static getErrorAsArray(errors) {
    const errorsList = [];
    if (errors?.response?.status === 422 && errors.response.data?.errors) {
      Object.keys(errors.response.data?.errors).map(key => {
        errorsList.push({name: key, errors: errors.response.data?.errors[key]});
      });
    }
    return errorsList;
  }

  static removeFormErrors(form) {
    const formItemItemsList = [];
    Object.keys(form.getFieldsValue()).map(key => formItemItemsList.push({name: key, errors: undefined}));
    return formItemItemsList;
  }
}

export default ErrorHandler;

import React, { useContext, useState } from "react";
import "../index.css";
import { useNavigate } from "react-router-dom";
import logoImg from "../assets/logo.png";
import Sider from "antd/es/layout/Sider";
import { Badge, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHomeAlt,
  faImage,
  faPowerOff,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../context/AuthContext";

const SideRoutes = [
  {
    title: "Dashboard",
    icon: <FontAwesomeIcon icon={faHomeAlt} />,
    link: "/dashboard",
  },
  {
    title: "Users",
    icon: <FontAwesomeIcon icon={faUser} />,
    link: "/users",
  },
  {
    title: "Banners",
    icon: <FontAwesomeIcon icon={faImage} />,
    link: "/banners",
  },
  {
    title: "Logout",
    icon: <FontAwesomeIcon icon={faPowerOff} />,
  },
];

export const SideDrawer = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      className="shadow-lg"
      breakpoint="lg"
      collapsed={collapsed}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        setCollapsed(collapsed);
        console.log(collapsed, type);
      }}
    >
      <div
        className="py-4 items-center justify-center flex"
        onClick={() => setCollapsed(!collapsed)}
      >
        <img src={logoImg} width={60} />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={1}
        items={SideRoutes.map((item, index) => ({
          key: String(index + 1),
          icon: item.icon,
          label: item.title,
          onClick: () => {
            if (item.title === "Logout") {
              logout();
            } else {
              navigate(item.link);
            }
          },
        }))}
      />
    </Sider>
  );
};

import React, { useEffect } from "react";
import { Button, Layout, Popconfirm, Space, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Header } from "../../components/Header";
import { UserForm } from "./UserForm";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const navigate = useNavigate();
  const formRef = React.useRef<any>();
  const [reload, setReload] = React.useState<any>(false);
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/users`)
      .then((res) => {
        setLoading(false);
        if (res.status < 300) {
          setList(res.data.response.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  }, [reload]);

  const onDelete = (id: string) => {
    setLoading(true);
    axios
      .delete(`/users/${id}`)
      .then((res) => {
        setLoading(false);
        if (res.status < 300) {
          setList(list.filter((i: any) => i.id !== id));
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "Email", dataIndex: "email" },
    { title: "Phone", dataIndex: "phone" },
    { title: "Role", dataIndex: "user_type",
    render: (user_type: any) => user_type == 1 ? 'Customer' : 'Photographer'

  },
    {
      title: "Created At",
      dataIndex: "updated_at",
      render: (date: string) => moment(date).format("lll"),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_: any, row: any) => (
        <Space>
          <Tooltip title={"Edit"}>
            <EditOutlined
              className="text-green-800"
              size={18}
              onClick={() =>
                navigate(`/users/${row.id}`, {
                  state: row,
                })
              }
            />
          </Tooltip>
          <Tooltip title={"Delete"} placement="bottom">
            <Popconfirm
              title="Confirmation"
              description={"Are you sure you want to delete this user?"}
              onConfirm={() => onDelete(row.id)}
            >
            <DeleteOutlined className="text-red-500" size={18} />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Layout>
      <LoadingIndicator visible={loading} />
      <Header title="Users" desc="You can manage your users from here" />
      <div className="p-5">
        <div className="float-right mb-5">
          <Button
            loading={loading}
            className="primary-btn"
            onClick={() => formRef.current.open()}
          >
            Add User
          </Button>
        </div>
        <Table columns={columns} dataSource={list} size="small" />
      </div>
      <UserForm ref={formRef} onComplete={() => setReload(!reload)} />
    </Layout>
  );
};

export default Users;

import React, { useEffect, useContext } from "react";
import { Button, Card, Form, Input, Layout, Select } from "antd";
import { Header } from "../../components/Header";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useLocation } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import { DESIGNATIONS } from "../../constant";
import Password from "antd/es/input/Password";
import AuthContext from "../../context/AuthContext";

const UserView = (props: any) => {
  const [form] = useForm();
  const { state } = useLocation();

  const [reload, setReload] = React.useState<any>(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>({});

  useEffect(() => {
    form.setFieldsValue(state);
    // getUser();
  }, []);

  const getUser = () => {
    setLoading(true);
    axios
      .get(`/users/${state.id}`)
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setData(res.data.response.data);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  const onUserSubmit = (values: any) => {
    setLoading(true);
    axios
      .patch(`/users/${data.id}`, values)
      .then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setData(res.data.response.data);
          ErrorHandler.showNotification(res);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <Layout>
      <LoadingIndicator visible={loading} />
      <Header
        backBtn={true}
        title={state.name}
        desc={state.email}
      />

      <div className="grid grid-cols-2 grid-flow-col gap-4 m-5">
        <Card title={"User Information"}>
          <Form
            form={form}
            layout={"vertical"}
            onFinish={onUserSubmit}
            autoComplete="off"
          >
            <Form.Item
              label="User Name"
              name={"name"}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input className="w-full" placeholder="Enter user name" />
            </Form.Item>
            <Form.Item
              label="Email"
              name={"email"}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input className="w-full" placeholder="Enter email address" />
            </Form.Item>
            <Form.Item label="Phone" name={"phone"}>
              <Input className="w-full" placeholder="Enter phone number" />
            </Form.Item>

            <Form.Item label="Date of Birth" name={"dob"}>
              <Input className="w-full" placeholder="Enter date of birth" />
            </Form.Item>
            {/* <Form.Item
              label="Password"
              name={"password"}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Password className="w-full" placeholder="Enter phone number" />
            </Form.Item> */}
            <Button className="primary-btn" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Form>
        </Card>
        
        <div className="w-full bg-white border border-gray-200 rounded-lg shadow">
          <div className="flex flex-col items-center pb-10">
            <img
              className="w-24 h-24 mb-3 rounded-full shadow-lg"
              src={data.image_url || require('../../assets/avatar.jpg')}
              alt={data.name}
            />
            <h5 className="mb-1 text-xl font-medium text-gray-900 ">
              {data.name}
            </h5>
            <span className="text-sm text-gray-500 dark:text-gray-400">
              {data.user_type == 1 ? 'Customer' : 'Photographer'}
            </span>
          </div>
          <div className="px-5">
            <h2 className="text-base font-semibold">Portfolio</h2>
            <p>{data.portfolio || 'N/A'}</p>
            <h2 className="text-base font-semibold mt-3">Rate</h2>
            <p>{data.min_rate}$ - {data.max_rate}$</p>
            <h2 className="text-base font-semibold mt-3">About</h2>
            <p>{data.about}</p>
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default UserView;

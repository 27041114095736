import React, { useEffect, useContext, useState } from "react";
import "../index.css";
import logoImg from "../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { Checkbox, Form, Input } from "antd";
import { Button } from "../components/Button";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import Cookies from "js-cookie";
import AuthContext from "../context/AuthContext";
import ErrorHandler from "../utils/ErrorHandler";

const Login = () => {
  const { token, setUser } = useContext(AuthContext);
  const [form] = useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true);
    axios
      .post("/login", values)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          const userData = res.data.response.data;
          setUser(userData);
          Cookies.set("@token", userData.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${userData.token}`;
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <div className="splash-container w-full">
      <div className="overlay flex-col p-5">
        <img src={logoImg} alt="Logo" style={{ width: 100, height: 100, marginBottom: 20 }} />
        <div className="bg-white p-5 w-full md:w-1/3 xl:w-1/4 rounded-xl">
          
          <h1 className="font-semibold text-lg text-center">Welcome to Backoffice!</h1>
          <p className="text-center mb-5 text-sm text-gray-600">Please enter your details here</p>

          <Form
            ref={form}
            layout="vertical"
            onFinish={onSubmit}
            autoComplete={false}
          >
            <Form.Item
              className="mb-3"
              label={"Email"}
              name={"email"}
              autoComplete={false}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input placeholder="Enter your email address or username" />
            </Form.Item>
            <Form.Item
              className="mb-3"
              label={"Password"}
              name={"password"}
              rules={[{ required: true, message: "This is a required field" }]}
            >
              <Input.Password placeholder="*********" />
            </Form.Item>
            <Button title="LOG IN" loading={loading} htmlType={"submit"} />
          </Form>

          <div className="flex justify-center mt-2">
            <a href="/forgot">Forgot Password?</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  InputNumber,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
  TabsProps,
  Tabs,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { DESIGNATIONS } from "../../constant";
import Password from "antd/es/input/Password";
import axios from "axios";
import ErrorHandler from "../../utils/ErrorHandler";

export const UserForm = React.forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const [form] = useForm<any>();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<any>({});
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    if (!visible) {
      setData({});
      form.resetFields();
    } 
  }, [visible]);

  React.useImperativeHandle(ref, () => ({
    open: (outlet: any = null) => {
      setVisible(true)
      if(outlet){
        setData(outlet);
        form.setFieldsValue(outlet)
      }
    },
    close: () => setVisible(false),
  }));

  const onSubmit = (values: any) => {
    setLoading(true);
    // values.phone = values.phone.toString()
    let axiosCall: any;
    if (data.id) {
      axiosCall = axios.patch(`/users/${data.id}`, values);
    } else {
      axiosCall = axios.post("/users", values);
    }

    axiosCall.then((res: any) => {
        setLoading(false);
        if (res.status < 300) {
          setData(res.data.response.data);
          props.onComplete();
          ErrorHandler.showNotification(res);
          setVisible(false);
        };
      })
      .catch((err: any) => {
        setLoading(false);
        ErrorHandler.showNotification(err);
      });
  };

  return (
    <Modal
      title={"Add User"}
      open={visible}
      onCancel={() => setVisible(!visible)}
      okButtonProps={{ className: "submit-btn" }}
      footer={false}
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout={"vertical"}
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Role"
          name={"user_type"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Select className="w-full" placeholder="Select a designation">
            <Select.Option value={1}>Customer</Select.Option>
            <Select.Option value={2}>Photographer</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="User Name"
          name={"name"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Input className="w-full" placeholder="Enter user name" />
        </Form.Item>
        <Form.Item
          label="Email"
          name={"email"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Input className="w-full" placeholder="Enter email address" />
        </Form.Item>
        <Form.Item label="Phone" name={"phone"}>
          <Input className="w-full" placeholder="Enter phone number" />
        </Form.Item>
        <Form.Item
          label="Password"
          name={"password"}
          rules={[{ required: true, message: "This is a required field" }]}
        >
          <Input className="w-full" placeholder="Enter password" />
        </Form.Item>
        <Button className="primary-btn" htmlType="submit" loading={loading}>
          Submit
        </Button>
      </Form>
    </Modal>
  );
});
